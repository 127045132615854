<template>
  <ModuleSubPage fullWidth>
    <BaseHasPermission :permissionNames="['role.update']">
      <v-row>
        <v-col cols="12">
          <BaseBackButton :route="{ name: 'ManagementRole' }">
            {{ $t("USER_MANAGEMENT.ROLES.BTN_LABEL_BACK") }}
          </BaseBackButton>
        </v-col>

        <v-col cols="12">
          <BaseHeadline size="3">
            {{ pageTitle }}
          </BaseHeadline>
        </v-col>
      </v-row>
      <AssignTable
        v-model="permissions"
        :fields="['displayName', 'displayName']"
        :loading="isLoading"
        @sync="syncRolePermissions($event)"
      />
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import AssignTable from "@components/AssignTable";

export default {
  name: "AssignPermissions",

  data() {
    return {
      isLoading: false,
      permissions: {
        assignable: [],
        assigned: [],
      },
      roleName: null,
    };
  },

  components: {
    AssignTable,
    ModuleSubPage,
  },

  computed: {
    roleId() {
      return parseInt(this.$route.params.roleId, 10);
    },

    pageTitle() {
      return this.$t("USER_MANAGEMENT.ROLES.ASSIGN_PERMISSION_PAGE_TITLE", {
        roleName: this.isLoading ? "..." : this.roleName,
      });
    },
  },

  methods: {
    async loadRoleData() {
      try {
        const { data } = await this.$axios.userManagement.showRole({
          roleId: this.roleId,
        });
        this.roleName = data.data.displayName;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadAssignedPermissions() {
      try {
        const { data } = await this.$axios.userManagement.listPermissions({
          inRoleIds: [this.roleId],
        });
        this.permissions.assigned = data.data;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadAssignablePermissions() {
      try {
        const { data } = await this.$axios.userManagement.listPermissions();

        this.permissions.assignable = data.data;

        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },

    async loadPermissions() {
      this.isLoading = true;

      try {
        await this.loadAssignedPermissions();
        await this.loadAssignablePermissions();

        return Promise.resolve();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async syncRolePermissions(permissionIds) {
      this.isLoading = true;

      try {
        await this.$axios.userManagement.syncRolePermissions({
          roleId: this.roleId,
          permissionIds,
        });
        await this.loadPermissions();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.loadRoleData();
    this.loadPermissions();
  },
};
</script>
